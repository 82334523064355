import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./ScanQr.module.css"; // Import the CSS module
import ScanQrImage from "../../assets/images/scanner.png";
import { showSuccessToast, showErrorToast } from "../../utils/Toaster";
import axios from "axios";

const ScanQr = ({ fetchData }) => {
  const inputRef = useRef(null);
  const [orderId, setOrderId] = useState("");

  const userData = JSON.parse(localStorage.getItem("userData"));
  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleInput = useCallback(
    debounce((value) => {
      setOrderId(value);
    }, 300),
    []
  );

  const printKot = async () => {
    try {
      const response = await axios
        .post(`http://localhost:8000/api/print-receipt`, {
          user_name: userData?.userName,
          order_id: orderId,
        })
        .catch(function (err) {
          showErrorToast(err.response.data.message);
        });

      if (response?.status === 200) {
        fetchData();
        showSuccessToast("Please receive your receipt!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOrderId("");
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    inputRef.current.focus();
    if (orderId !== "") {
      printKot();
    }
  }, [orderId]);

  const handleBlur = () => {
    inputRef.current.focus();
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        Please use the scanner to scan your QR code
      </h2>
      <img src={ScanQrImage} alt="QR Scanner" className={styles.image} />
      <input
        type="text"
        ref={inputRef}
        onChange={(e) => handleInput(e.target.value)}
        onBlur={handleBlur}
        className={styles.input}
        style={{ opacity: 0 }}
      />
    </div>
  );
};

export default ScanQr;
