import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";

const OrderHistory = () => {
    const [orderHistory, setOrderHistory] = useState();

    // Fetch order history
    useEffect(() => {
        const getOrderHistory = async () => {
            try {
                const response = await axiosPrivate.get(`/getUserOrder`);
                if (response.status === 200) {
                    setOrderHistory(response?.data?.Data);
                }
            } catch (err) {
                showErrorToast(err.message);
            }
        };
        getOrderHistory();
    }, []);

    return (
        <div className="content">
            <div className="section-body mt-2">
                <div className="container">
                <div className="section-title mb-3 mt-3">
                    <h3>
                 Order History
                    </h3>
                </div>
                    <div className="row" style={{height: "80dvh" , overflow: "scroll"}}>
                        {orderHistory && orderHistory.length > 0 ? (
                            orderHistory?.map((order) => (
                                <div key={order.id} className="col-md-12 mb-2">
                                    <div className="card shadow-sm">
                                        <div
                                            className="d-flex justify-content-between card-header text-white"
                                            style={{
                                                backgroundColor: "#f53d3d",
                                                padding: "15px",
                                                borderRadius: "5px 5px 0 0", // Rounded top corners
                                            }}
                                        >
                                            <div>
                                                <h6 style={{ marginBottom: "10px", fontWeight: "bold" }}>
                                                    Order Id: {order.id}
                                                </h6>
                                            </div>
                                            <div style={{ fontSize: "14px", lineHeight: "1.6" }} className="d-flex flex-column justify-content-center align-items-end">
                                                <div>
                                                    <strong>Invoice Number:</strong> {order.invoice_number}
                                                </div>
                                                <div>
                                                    <strong>Total Amount:</strong> ₹{order.total_amount}
                                                </div>
                                                <div>
                                                    <strong>Status:</strong>{" "}
                                                    <span
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        {order.order_status}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            {order?.items?.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="d-flex align-items-center mb-3"
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <p className="mb-0">
                                                            {item.quantity} x {item.menu.title}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No orders found.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderHistory;
