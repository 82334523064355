import "./App.css"; // Import CSS file for styling
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Layouts/Main"; // Import Main layout component

// Import pages
import UsersList from "./Pages/Users/UsersList";
import AddEditUsers from "./Pages/Users/AddEditUser";
import Profile from "./Pages/Profile/Profile";
import UserPermission from "./Pages/Users/UserPermission";
import Roles from "./Pages/Roles/Roles";
import Login from "./Pages/Auth/Login";
import AddEditRole from "./Pages/Roles/AddEditRole";
import RolePermission from "./Pages/Roles/RolePermission";
import AuthMain from "./Layouts/AuthMain";
import Flow from "./Pages/Roles/Flow";
import NotFound from "./components/NotFound/NotFound";
import Forbidden from "./components/Forbidden/Forbidden";
import UnderMaintenance from "./Pages/UnderMaintenance/UnderMaintenance";
import CustomerDashboard from "./customer/CustomerLayout/CustomerDashboard";
import CustomerMain from "./Layouts/CustomerMain";
import Checkout from "./customer/Checkout/Checkout";
import FoodCounter from "./Pages/food-counter/FoodCounter";
import AddEditFoodCounter from "./Pages/food-counter/AddEditFoodCounter";
import AddEditMenu from "./Pages/menu/AddEditMenu";
import MenuList from "./Pages/menu/MenuList";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import OrdersList from "./Pages/orders/OrdersList";
import MenuCategoryList from "./Pages/menu-category/MenuCategoryList";
import AddEditMenuCategory from "./Pages/menu-category/AddEditMenuCategory";
import UserBalanceListing from "./Pages/wallet/UserBalanceListing";
import CreateOrder from "./Pages/orders/CreateOrder";
import CustomerProfile from "./customer/CustomerProfile/CustomerProfile";
import CustomerStatement from "./customer/CustomerStatement/CustomerStatement";
import ScanQr from "./Pages/orders/ScanQr";
import Reports from "./Pages/Reports/Reports";
import AuditLogs from "./Pages/Audit Logs/AuditLogs";
import AddEditSubscription from "./Pages/orders/AddEditSubscription";
import GuestQr from "./customer/GuestQr/GuestQr";
import OrderHistory from "./Pages/Order History/OrderHistory";

function App() {

  const userData = JSON.parse(localStorage.getItem("userData")) || {};



  return (
    <BrowserRouter>
      <Routes>
        {/* Admin layout routes */}
        <Route
          path='/'
          element={
            userData?.role_name === "customer" ? <CustomerMain /> : <Main />
          } //there is outlet in Main which will render pages according to path*/
        >
          <Route
            path=''
            element={
              userData?.role_name === "customer" ? (
                <CustomerDashboard />
              ) : (
                <OrdersList />
              )
            }
          />
          {/* Dashboard */}
          <Route
            path='profile'
            element={<Profile />}
          />
          {/* Users routes */}
          <Route path='users'>
            <Route
              path=''
              element={<UsersList />}
            />
            <Route
              path='add'
              element={<AddEditUsers />}
            />
            <Route
              path='edit/:id'
              element={<AddEditUsers />}
            />
            <Route
              path='permissions/:id'
              element={<UserPermission />}
            />
          </Route>
          {/* Roles routes */}
          <Route path='roles'>
            <Route
              path=''
              element={<Roles />}
            />
            <Route
              path='add'
              element={<AddEditRole />}
            />
            <Route
              path='edit/:id'
              element={<AddEditRole />}
            />
            <Route
              path='permissions/:id'
              element={<RolePermission />}
            />
            <Route
              path='flow'
              element={<Flow />}
            />
          </Route>
          {/* food counter route */}
          <Route path='food-counter'>
            <Route
              path=''
              element={<FoodCounter />}
            />
            <Route
              path='add'
              element={<AddEditFoodCounter />}
            />
            <Route
              path='edit/:id'
              element={<AddEditFoodCounter />}
            />
          </Route>
          {/* Menu Categories route */}
          <Route path='menu-category'>
            <Route
              path=''
              element={<MenuCategoryList />}
            />
            <Route
              path='add'
              element={<AddEditMenuCategory />}
            />
            <Route
              path='edit/:id'
              element={<AddEditMenuCategory />}
            />
          </Route>
          {/* Menus route */}
          <Route path='menu'>
            <Route
              path=''
              element={<MenuList />}
            />
            <Route
              path='add'
              element={<AddEditMenu />}
            />
            <Route
              path='edit/:id'
              element={<AddEditMenu />}
            />
          </Route>
          <Route path="subscription-create" element={<AddEditSubscription />} />
          <Route path="subscription/:id" element={<AddEditSubscription />} />
          <Route path='orders'>
            <Route
              path=''
              element={<OrdersList />}
            />
            <Route
              path='create'
              element={<CreateOrder />}
            />
            <Route
              path='scan-qr'
              element={<ScanQr />}
            />
          </Route>
          <Route path='user-wallet'>
            <Route
              path=''
              element={<UserBalanceListing />}
            />
          </Route>
          <Route path='reports'>
            <Route
              path=''
              element={<Reports />}
            />
          </Route>
          <Route path='audit-logs'>
            <Route
              path=''
              element={<AuditLogs />}
            />
          </Route>
          {/* 404 route */}
          <Route
            path='/*'
            element={<NotFound />}
          />
          {/* 403 route */}
          <Route
            path='/forbidden'
            element={<Forbidden />}
          />{" "}
        </Route>

        {/* Customer Layout routes */}
        <Route
          path='/'
          element={<CustomerMain />}
        >
          <Route
            path='order-food'
            element={<CustomerDashboard />}
          />
          <Route
            path='checkout'
            element={<Checkout />}
          />
          <Route
            path='guest-qr'
            element={<GuestQr />}
          />
          <Route
            path="order-history"
            element={<OrderHistory />}
          />
          <Route path='user-profile'>
            <Route
              path=''
              element={<CustomerProfile />}
            />
            <Route
              path='statement'
              element={<CustomerStatement />}
            />
          </Route>
        </Route>

        {/* Auth layout routes */}
        <Route
          path='/'
          element={<AuthMain />}
        >
          <Route
            path='login'
            element={<Login />}
          />
          <Route
            path='/forgot-password'
            element={<ForgotPassword />}
          />
          <Route
            path='maintenance'
            element={<UnderMaintenance />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
