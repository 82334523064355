import React, { useState } from "react";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import "jspdf-autotable"; // Import autoTable plugin

import { TabPanel, TabView } from "primereact/tabview";
import MonthlySales from "./MonthlySales";
import PaymentMethods from "./PaymentMethods";
import CategorySales from "./CategorySales";
import ItemSales from "./ItemSales";
import Discount from "./Discount";
import DailyWallet from "./DailyWallet";

const Reports = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <div className="content">
        <TabView
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
        >
          <TabPanel header="Monthly Sales">
            <MonthlySales />
          </TabPanel>
          <TabPanel header="Payment Methods">
            <PaymentMethods />
          </TabPanel>
          <TabPanel header="Category Sales">
            <CategorySales />
          </TabPanel>
          <TabPanel header="Item Sales">
            <ItemSales />
          </TabPanel>
          <TabPanel header="Discounted Sales">
            <Discount />
          </TabPanel>
          <TabPanel header="Daily Wallet">
            <DailyWallet />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};

export default Reports;
